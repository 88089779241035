import React from "react";

const CustomModalComponent = ({
  state = false,
  isProcessing = false,
  header = "",
  description = "",
  handleCloseModal = () => {},
  inputFuct = () => {},
  icon,
}) => {
  return (
    <>
      <div className="custom-modal-body" onClick={(e) => e.stopPropagation()}>
        <div className="delete-property-context">
          <div className="delete-property-header">{header}</div>
          <div className="delete-property-body">{description}</div>
        </div>
        <div className="delete-property-trashbin">
          <img src={icon} alt="icon" />
        </div>
      </div>
      <div className="custom-modal-button-group">
        {state ? (
          <>
            <button
              className="btn delete-property"
              type="button"
              onClick={handleCloseModal}
            >
              Ok
            </button>
          </>
        ) : (
          <>
            <button className="btn" type="button" onClick={handleCloseModal}>
              Cancel
            </button>
            <button
              className="btn delete-property"
              type="button"
              onClick={inputFuct}
            >
              {isProcessing ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Ok"
              )}
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default CustomModalComponent;
