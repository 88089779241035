/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, LinearProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { deleteDocument, uploadDocument } from "../../../../api/scenario";
import CustomModalComponent from "../../../../components/custom-modal-component";
import ApplicationLayoutContext from "../../../../context/application-layout-context";
import { useQueryInvalidationWithNotification } from "../../../../hooks";
import { TrashBin } from "../../../../images";
import { Download, MagnifyingGlass, RecycleBin } from "../../../../svgs";

const changeDownloadRequestToTrue = (fileURL = "") =>
  fileURL.replace("isDownloadRequest=false", "isDownloadRequest=true");

const FileItem = ({
  fileData = {},
  setFiles,
  selectedScenarioId,
  opportunityId = "",
}) => {
  const { handleViewPhoto } = useContext(ApplicationLayoutContext);
  const invalidateQueries = useQueryInvalidationWithNotification();
  const [uploadPercent, setUploadPercent] = useState(0);
  const [isDeleteFile, setIsDeleteFile] = useState(false);
  const options = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      if (percent < 100) {
        setUploadPercent(percent);
      }
    },
  };
  const deleteContent = {
    header: "Delete Document?",
    body: "Are you sure you want to delete this document?",
  };
  const uploadDocumentMutation = useMutation(
    ({ params }) => {
      return uploadDocument(selectedScenarioId, params, options);
    },
    {
      onSuccess: () => {
        invalidateQueries("documentList").then(() => {
          setFiles((prevState) =>
            prevState.filter((file) => file.id !== fileData.id)
          );
        });
      },
      onError: () => {
        setFiles((prevState) =>
          prevState.filter((file) => file.id !== fileData.id)
        );
      },
    }
  );

  const deleteDocuments = useMutation(
    // eslint-disable-next-line no-shadow
    ({ selectedScenarioId, documentId }) =>
      deleteDocument(selectedScenarioId, documentId),
    {
      onSuccess: () => {
        invalidateQueries("documentList");
      },
    }
  );

  const handleCloseModal = () => {
    setIsDeleteFile(false);
  };

  const handleShowModal = () => {
    setIsDeleteFile(true);
  };

  const handleDeleteDocument = (documentId) => {
    if (documentId.includes("local")) {
      setFiles((prevState) =>
        prevState.filter((file) => file.id !== documentId)
      );
    } else {
      deleteDocuments.mutate({ selectedScenarioId, documentId });
    }
  };

  useEffect(() => {
    if (opportunityId && fileData.id.includes("local")) {
      const formData = new FormData();
      formData.append(`files`, fileData);
      uploadDocumentMutation.mutate({ params: formData });
    }
  }, []);

  return (
    <div className="file-container font-size-normal" key={fileData.id}>
      <div className="file-name">
        {uploadPercent > 0 ? (
          <LinearProgress variant="determinate" value={uploadPercent} />
        ) : (
          fileData.name
        )}
      </div>
      <div className="file-button-group">
        <div
          role="button"
          className="font-weight-semibold file-delete-button"
          onClick={() => handleShowModal()}
          onKeyDown={() => {}}
          tabIndex="0"
        >
          <RecycleBin />
        </div>
        <div
          role="button"
          className="font-weight-semibold file-view-button"
          onClick={() =>
            handleViewPhoto(fileData.url, fileData.extension || fileData.name)
          }
          onKeyDown={() => {}}
          tabIndex="0"
        >
          <MagnifyingGlass />
        </div>
        <div
          role="button"
          className="font-weight-semibold file-download-button"
        >
          <a
            href={changeDownloadRequestToTrue(fileData.url)}
            download={fileData.name}
          >
            <Download />
          </a>
        </div>
      </div>
      <Dialog
        open={isDeleteFile}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal-container"
      >
        <CustomModalComponent
          header={deleteContent.header}
          description={deleteContent.body}
          handleCloseModal={handleCloseModal}
          state={deleteDocuments.isSuccess}
          isProcessing={deleteDocuments.isLoading}
          inputFuct={() => handleDeleteDocument(fileData.id)}
          icon={TrashBin}
        />
      </Dialog>
    </div>
  );
};

FileItem.propTypes = {};

export default FileItem;
