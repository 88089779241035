import React from "react";
import PropTypes from "prop-types";

const ApplicationTitle = ({ title = "" }) => {
  return <div className="application-title">{title}</div>;
};

ApplicationTitle.propTypes = {
  title: PropTypes.string,
};

export default ApplicationTitle;
