import React from "react";
import { useDropzone } from "react-dropzone";
import { pdfjs } from "react-pdf";
import { v4 as uuidv4 } from "uuid";
import { Upload } from "../../../../svgs";
import FileList from "../../components/file-list";
import ApplicationTitle from "./application-title";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentCenter = ({
  files = [],
  setFiles,
  selectedScenarioId = "",
  opportunityId = "",
}) => {
  const updateFileList = (acceptedFiles) => {
    setFiles((prevState) => {
      return [
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            id: `local-${uuidv4()}`,
            url: URL.createObjectURL(file),
          })
        ),
        ...prevState,
      ];
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".png, .jpg, .jpeg, .pdf, .doc, .docx",
    multiple: true,
    onDrop: (acceptedFiles) => {
      updateFileList(acceptedFiles);
    },
    maxSize: 20971520,
  });

  return (
    <>
      <ApplicationTitle title="Commonly Requested Documents." />
      <div className="d-flex justify-content-around mb-5 font-size-normal">
        <div>Drivers License</div>
        <div>2 x Most Recent Payslips</div>
        <div>Bank Statements</div>
        <div>Council Rates Notice</div>
      </div>
      <div className="dropzone-container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div className="upload-file-icon">
            <Upload />
          </div>
          <p className="font-size-lg mb-2">Drop files here</p>
          <p className="font-size-normal">
            Or <span className="color-secondary">browse files</span> from your
            computer
          </p>
        </div>
        {files?.length ? (
          <div className="mt-5">
            <p className="font-weight-semibold ml-4">Document Name</p>
            <FileList
              fileList={files || []}
              setFiles={setFiles}
              selectedScenarioId={selectedScenarioId}
              opportunityId={opportunityId}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

DocumentCenter.propTypes = {};

export default DocumentCenter;
