/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import FileItem from "../start-loan-application/components/file-item";

const FileList = ({
  fileList = [],
  setFiles = () => {},
  selectedScenarioId = "",
  opportunityId = "",
}) => {
  return (
    fileList.length &&
    fileList.map((file) => (
      <FileItem
        key={file.id}
        fileData={file}
        setFiles={setFiles}
        selectedScenarioId={selectedScenarioId}
        opportunityId={opportunityId}
      />
    ))
  );
};

FileList.propTypes = {
  fileList: PropTypes.array,
};

export default FileList;
